/* * {
    box-sizing: border-box;
  }
   */
  /* body,
  html {
    background-color: #f6f9fc;
    font-size: 18px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    margin: 0;
  } */
  
  .DemoPickerWrapper {
    padding: 0 12px;
    font-family: "Source Code Pro", monospace;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    background: white;
    margin: 24px 0 48px;
    width: 100%;
  }
  
  .DemoPicker {
    font-size: 18px;
    border-radius: 3px;
    background-color: white;
    height: 48px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    border: 0;
    width: 100%;
    color: #6772e5;
    outline: none;
    background: transparent;
  
    -webkit-appearance: none;
  }
  
  .DemoWrapper {
    margin: 0 auto;
    max-width: 500px;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .Demo {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 40%;
  }
  /* fieldset {
    border-style: none;
    padding: 5px;
    margin-left: -5px;
    margin-right: -5px;
    background: rgba(18, 91, 152, 0.05);
    border-radius: 8px;
  } */

  label {
    color: #6b7c93;
    font-weight: 300;
    letter-spacing: 0.025em;
  }
  
  .success-wrapper, .cancel-wrapper{
    background-color: white;
    min-height: 60vh;
  
  }

  .spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

  .success, .cancel{
    width: 1000px;
  margin: auto;
  margin-top: 160px;
    background-color: #dcdcdc;
    padding: 50px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .success .icon {
     color: green;
     font-size: 40px;
  }
  .success h2{
    text-transform: capitalize;
    margin-top: 15px 0px;
    font-weight: 900;
    font-size: 40px;
    color:#324d67;
  }
  .success .email-msg{
     font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
  .cancel p{
    font-size: 20px;
    font-weight: 600;
  }
  .success .description{
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin: 10px;
    margin-top: 30px;
  }
  .success .description .email{
    margin-left: 5px;
    color: #f02d34;
  }
  
  .stripe  {
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background-color: #6772e5;
    text-decoration: none;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    width:100%;
    
  }

  /* button{
    background-color: rgba(23,26,32,1);
    padding: 12px 20px;
    width: 30%;
    border-radius: 20px;
    outline: none;
    border: none;
    font-size: 0.8rem;
    font-weight: 400;
    letter-spacing: 0.6px;
    opacity: 0.9;
     */
/* } */
  
  button:hover {
    color: #fff;
    cursor: pointer;
    /* background-color: #7795f8; */
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
  
  input,
  .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: "Source Code Pro", monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
      rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
  }
  
  input::placeholder {
    color: #aab7c4;
  }
  
  input:focus,
  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
      rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }
  
  .StripeElement.IdealBankElement,
  .StripeElement.FpxBankElement,
  .StripeElement.PaymentRequestButton {
    padding: 0;
  }
  
  .StripeElement.PaymentRequestButton {
    height: 40px;
  }
  
  @-webkit-keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }